import React from 'react';
import MarketingPage from './MarketingPage'; // Adjust the path as necessary

const App: React.FC = () => {
  return (
    <div>
      <MarketingPage />
    </div>
  );
};

export default App;
